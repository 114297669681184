// @flow
import * as React from 'react';
import classNames from 'classnames';
import TeaserSliderGenerator from './teaser-slider';
import type { Multiplicators } from './teaser-slider';

type Props = {
  children: Array<React.Node>,
  variant: '25-25-25-25' | '50-25-25',
  className?: string,
  teaserClassName?: string,
  multiplicators?: Multiplicators,
};

export default class TeaserSlider extends React.Component<Props> {
  $ref: ?HTMLElement;

  instance: ?TeaserSliderGenerator;

  componentDidMount() {
    const { multiplicators } = this.props;

    if (this.$ref) {
      this.instance = new TeaserSliderGenerator(this.$ref, multiplicators);
    }
  }

  componentWillUnmount() {
    if (this.instance) {
      this.instance.deinit();
    }
  }

  render() {
    const {
      children, variant, className, teaserClassName,
    } = this.props;

    return (
      <div className={classNames('teaser-slider', `teaser-slider--${variant}`, className)} ref={($ref) => {
        this.$ref = $ref;
      }}>
        <div className="teaser-slider__inner">
          <div className="teaser-slider__teasers">
            {children.map((child, index) => <div
              key={index}
              className={classNames('teaser-slider__teaser', teaserClassName)}
            >
              {child}
            </div>)}
          </div>
        </div>
      </div>
    );
  }
}

// @flow
import * as React from 'react';
import classNames from 'classnames';
import randomId from '../../../javascripts/utils/random-id';
import Headline from '../../atoms/headline/Headline';
import MoreLink from '../../atoms/more-link/MoreLink';
import Image from '../../atoms/image/Image';

type Props = {
  style: 'horizontal' | 'vertical',
  headline: string,
  link: string,
  linkText: string,
  imageSrc: string,
  kicker: string,
  className?: string,
  imageAlt?: string,
  imageWidth?: number,
  imageHeight?: number,
  imageLazyload?: boolean,
  imageCover?: boolean,
  imageClassName?: string,
  imageDecorative?: boolean,
  linkDownload?: string,
  linkClassName?: string,
  id?: string,
};

const Teaser = (props: Props) => {
  const {
    style,
    headline,
    kicker,
    className,
    imageSrc,
    imageAlt,
    imageWidth,
    imageHeight,
    imageLazyload,
    imageCover,
    imageClassName,
    imageDecorative,
    link,
    linkDownload,
    linkClassName,
    linkText,
    id = `teaser-${randomId()}`,
  } = props;

  const classes = classNames(
    {
      teaser: true,
      [`teaser--${style}`]: true,
    },
    className,
  );

  const image = {
    src: imageSrc,
    alt: imageAlt || headline,
    width: imageWidth,
    height: imageHeight,
    lazyload: imageLazyload,
    cover: imageCover,
    className: imageClassName,
    decorative: imageDecorative,
  };

  const children = (
    <React.Fragment>
      {linkText}
      <span className="u-hidden-visually">{headline}</span>
    </React.Fragment>
  );

  const moreLink = {
    children,
    link,
    download: linkDownload,
    className: linkClassName,
  };

  return (
    <article className={classes} id={id}>
      <div className="teaser__image">
        <Image {...image} cover />
      </div>
      <div className="teaser__content">
        <div className="teaser__header">
          <div className="teaser__headline">
            <Headline
              id={`${id}-headline`}
              level={3}
              size="teaser"
              kicker={kicker}
              kickerAttr={{ className: 'teaser__kicker' }}
            >
              {headline}
            </Headline>
          </div>
        </div>

        <div className="teaser__footer">
          <MoreLink {...moreLink} className="teaser__more-link teaser__overlay-link" ariaDescribedby={`${id}-headline`} />
        </div>
      </div>
    </article>
  );
};

Teaser.defaultProps = {
  style: 'horizontal',
};

export default Teaser;

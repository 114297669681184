// @flow
import * as React from 'react';
import classNames from 'classnames';
import Input from '../../atoms/input/Input';
import Button from '../../atoms/button/Button';
import Label from '../../atoms/label/Label';
import { t } from '../../../javascripts/utils/withTranslation';

type Props = {
  id: string,
  placeholder: string,
  name: string,
  onChange?: (SyntheticInputEvent<HTMLInputElement>) => any,
  value?: string,
  style?: 'primary' | 'secondary',
  className?: string,
  iconTitle?: string,
  action?: string,
  method?: string,
  label?: string,
  children?: React.Node,
};

const SearchInput = (props: Props) => {
  const {
    id,
    placeholder,
    name,
    style,
    className,
    onChange,
    iconTitle,
    value,
    action,
    method,
    label,
    children,
  } = props;

  const Tag = action ? 'form' : 'div';

  const classes = classNames(
    {
      'search-input': true,
    },
    className,
  );

  const formAttributes = {
    method: method || (action && 'get'),
    action,
  };

  const inputAttributes = {
    value,
    placeholder,
    name,
    id,
    onChange,
    color: style,
    type: 'search',
    className: 'search-input__input',
  };

  const buttonAttributes = {
    icon: 'search',
    type: 'submit',
    iconTitle,
    style,
    small: true,
    className: 'search-input__button',
  };

  const labelClasses = classNames({
    'search-input__label': true,
    'u-hidden-visually': !label,
  });

  return (
    <Tag className={classes} role="search" {...formAttributes}>
      <Label className={labelClasses} htmlFor={id}>{label || t('Suchbegriff')}</Label>
      <Input {...inputAttributes} />
      <Button {...buttonAttributes} />
      {children}
    </Tag>
  );
};

export default SearchInput;

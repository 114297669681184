import Lottie from 'lottie-web/build/player/lottie.min';
import Participate from './data/participate';

// Animation types
const types = {
  participate: Participate,
};

const animation = ($animation) => {
  const type = [$animation.dataset.type];

  if (type && type in types) {
    Lottie.loadAnimation({
      container: $animation,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: types[type],
    });

    $animation.getElementsByTagName('svg')[0].setAttribute('aria-hidden', true);
  }
};

document.querySelectorAll('.js-animation')
  .forEach($animation => animation($animation));

// @flow
import * as React from 'react';
import ReactModal from 'react-modal';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Button from '../../atoms/button/Button';
import { MEDIA_QUERIES } from '../../../javascripts/constants';
import { t } from '../../../javascripts/utils/withTranslation';

type Props = {
  children: React.Node,
  customHead?: Function,
  dialogParent: Element,
  onClose: () => void,
  className?: string,
  dialogScrollInnerClassName?: string,
  isOpen?: boolean,
  closeButton?: boolean,
};

type State = {
  isOpen: boolean,
};

class Dialog extends React.Component<Props, State> {
  $inner: ?HTMLElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: props.isOpen === undefined ? true : props.isOpen,
    };
  }

  static getDerivedStateFromProps(props: Props) {
    return {
      isOpen: props.isOpen === undefined ? true : props.isOpen,
    };
  }

  onClose() {
    this.setState({ isOpen: false });
    this.props.onClose();
  }

  componentWillUnmount() {
    if (this.$inner) {
      enableBodyScroll(this.$inner);
    }
  }

  render() {
    const {
      children,
      customHead,
      className,
      onClose,
      dialogParent,
      closeButton = false,
      dialogScrollInnerClassName,
    } = this.props;

    return (
      <ReactModal
        isOpen={this.state.isOpen}
        contentLabel="Dialog Overlay"
        portalClassName="dialog"
        overlayClassName="dialog__overlay"
        className={classNames('dialog__content', className)}
        parentSelector={() => dialogParent}
        onRequestClose={onClose}
        role="dialog"
      >
        <div className="dialog__inner" ref={($inner) => {
          if ($inner) {
            this.$inner = $inner;
            disableBodyScroll(this.$inner);
          }
        }}>
          <div className={classNames('dialog__scroll-inner', dialogScrollInnerClassName)}>
            {children}
          </div>
        </div>

        {closeButton && <div className="dialog__head">
          <MediaQuery query={MEDIA_QUERIES.m}>
            {matches => (matches ? (
              <Button
                icon="close"
                className="dialog__close"
                outline={true}
                small={true}
                onClick={this.onClose.bind(this)}
              >
                {t('Schließen')}
              </Button>
            ) : (
              <Button
                icon="close"
                iconTitle={t('Schließen')}
                className="dialog__close"
                outline={true}
                small={true}
                onClick={this.onClose.bind(this)}
              />
            ))}
          </MediaQuery>
        </div>}

        {customHead && <div className="dialog__custom-head">
          {customHead()}
        </div>}
      </ReactModal>
    );
  }
}

export default Dialog;

// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {
  tagName?: string | 'label',
  id?: string,
  htmlFor: string,
  className?: string,
  children: string | React.Node,
};

const Label = (props: Props) => {
  const {
    id,
    htmlFor,
    children,
    className,
    tagName,
  } = props;

  const Tag = tagName || 'label';

  const classes = classNames(
    'label',
    className,
  );

  return (
    <Tag className={classes} htmlFor={htmlFor} id={id}>
      {children}
    </Tag>
  );
};

export default Label;

// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {
  link?: string,
  download?: string,
  className?: string,
  ariaDescribedby?: string,
  children: string | React.Node,
};

const MoreLink = (props: Props) => {
  const {
    link, download, children, className, ariaDescribedby,
  } = props;
  const Tag = (link && 'a') || 'span';

  const classes = classNames(
    {
      'more-link': true,
    },
    className,
  );

  const attributes = {
    'aria-describedby': ariaDescribedby,
    href: link,
    target: download && '_blank',
    download,
  };

  return (
    <Tag className={classes} {...attributes}>
      <span className="more-link__text">{children}</span>
    </Tag>
  );
};

export default MoreLink;

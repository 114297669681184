// @flow
import * as React from 'react';
import queryString from 'query-string';
import SearchInput from '../../molecules/search-input/SearchInput';
import Headline from '../../atoms/headline/Headline';
import Button from '../../atoms/button/Button';
import Teaser from '../../molecules/teaser/Teaser';
import TeaserSlider from '../teaser-slider/TeaserSlider';
import Fetch from '../../../javascripts/utils/Fetch';
import Spinner from '../../atoms/spinner/Spinner';
import type { Item } from './HeaderNavigationDialog';
import { t } from '../../../javascripts/utils/withTranslation';

type ParsedUrl = { url: string, query: { [*]: mixed } };
type KeyValue = [string, mixed];
type Query = Array<KeyValue>;

const SectionSearch = ({ index, item }: { index: number, item: Item }) => {
  if (!item.sectionSearch) {
    return null;
  }

  const searchQuery: ParsedUrl = queryString.parseUrl(item.sectionSearch);
  const query: Query = Object.entries(searchQuery.query);
  const input: KeyValue = query.find(q => q[1] === '%s') || ['s', '%s'];
  const hiddenFields: Query = query.filter(q => q[1] !== '%s');

  return (
    <SearchInput
      id={`search-input-section-${index}`}
      className="header__dialog-search"
      action={searchQuery.url}
      placeholder={t('{title} durchsuchen', {
        title: item.title,
      })}
      name={input[0]}
      style="primary"
      iconTitle={t('Suchen')}
    >
      {hiddenFields.map((hiddenField, hiddenFieldIndex) => (
        <input key={hiddenFieldIndex} type="hidden" name={hiddenField[0]} value={hiddenField[1]} />
      ))}
    </SearchInput>
  );
};

const SectionHeadline = ({ item }: { item: Item }) => (
  <div className="header__dialog-headline">
    <Headline size="teaser" level={2}>{item.sectionName || ''}</Headline>
  </div>
);

const SectionContent = ({ data }: { data: Object }) => (
  <div className="header__dialog-teasers">
    <TeaserSlider
      className="header__dialog-teaser-slider"
      teaserClassName="header__dialog-teaser"
      variant="25-25-25-25"
      multiplicators={{
        s: 1, m: 1, l: 2, xl: 2,
      }}
    >
      {data.articles.map((teaser, tIndex) => (
        <Teaser
          key={`teaser-${tIndex}`}
          headline={teaser.title}
          imageSrc={teaser.imageSrc}
          kicker={teaser.kicker}
          link={teaser.link}
          linkText={teaser.linkText}
          style="vertical"
        />
      ))}
    </TeaserSlider>
  </div>
);

const SectionButton = ({ item, onClick }: { item: Item, onClick: Function }) => (
  <div className="header__dialog-button">
    <Button link={item.link} outline={true} onClick={onClick}>{item.sectionButton}</Button>
  </div>
);

const Section = ({ index, item, onClick }: { index: number, item: Item, onClick: Function }) => (
  item.sectionApiEndpoint ? (
    <Fetch apiUrl={item.sectionApiEndpoint} delay={200}>
      {({
        loading, data, error, pastDelay,
      }) => {
        if (loading) {
          return <Spinner label={t('Inhalte werden geladen...')} />;
        }

        if (error) {
          return <Spinner icon="error" label={t('Inhalte konnten nicht geladen werden.')} />;
        }

        if (!loading && pastDelay && data) {
          return <div className="header__dialog-fadein">
            {item.sectionName && <SectionHeadline item={item} />}
            <SectionSearch item={item} index={index} />
            <SectionContent item={item} data={data} />
            {item.sectionButton && <SectionButton item={item} onClick={onClick} />}
          </div>;
        }

        return null;
      }}
    </Fetch>
  ) : (
    <React.Fragment>
      <SectionHeadline item={item} />
      <SectionSearch item={item} index={index} />
      <SectionButton item={item} onClick={onClick} />
    </React.Fragment>
  )
);

export default Section;

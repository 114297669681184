import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import { MEDIA_QUERIES } from 'javascripts/constants';
import HeaderToggleButton from './HeaderToggleButton';
import HeaderNavigationDialog from './HeaderNavigationDialog';

// Toggle button
let headerToggleButton = null;
function onMediaQueryChange(mq) {
  const isDesktop = mq.matches;

  // Deinit on desktop
  if (isDesktop && headerToggleButton) {
    headerToggleButton.deconstructor();
    headerToggleButton = null;

  // Init on mobile
  } else if (!isDesktop && !headerToggleButton) {
    const $toggleButton = document.querySelector('#header-toggle');
    if ($toggleButton) {
      headerToggleButton = new HeaderToggleButton($toggleButton);
    }
  }
}

// Init media query listener
const mql = window.matchMedia(MEDIA_QUERIES.l);
mql.addListener(onMediaQueryChange);
onMediaQueryChange(mql);

// Click handler
document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-navigation-dialog');

  if ($target) {
    // Prevent navigation
    event.preventDefault();

    // Build tree
    const items = [];
    $target.parentNode
      .querySelectorAll('.header__subnavigation-item-wrap--parent')
      .forEach(($item) => {
        // Prepare item
        const $link = $item.querySelector('.header__subnavigation-item');
        const item = {
          title: $link.textContent,
          link: $link.href,
          sectionName: $link.getAttribute('data-section-name'),
          sectionApiEndpoint: $link.getAttribute('data-section-api-endpoint'),
          sectionSearch: $link.getAttribute('data-section-search'),
          sectionButton: $link.getAttribute('data-section-button'),
          items: [],
        };

        // Add subitems
        $item
          .querySelectorAll('.header__subnavigation-list .header__subnavigation-item')
          .forEach(($subitem) => {
            item.items.push({
              title: $subitem.textContent,
              link: $subitem.href,
            });
          });

        // Add item to items collection
        items.push(item);
      });

    // Prepare mount point
    const $dialogParent = h('#header-dialog');
    const $mountPoint = h('#header-dialog-mountpoint');
    const $wrapper = h('div', $dialogParent, $mountPoint);
    document.body.appendChild($wrapper);

    // Render dialog
    ReactDOM.render(<HeaderNavigationDialog
      dialogParent={$dialogParent}
      items={items}
      onClose={() => {
        ReactDOM.unmountComponentAtNode($mountPoint);
        $wrapper.parentNode.removeChild($wrapper);
        $target.focus();
      }}
    />, $mountPoint);
  }
});

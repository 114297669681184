// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {
  type: string,
  placeholder: string,
  onChange: (SyntheticInputEvent<HTMLInputElement>) => any,
  value?: string,
  name?: string,
  color?: string,
  invalid?: boolean,
  disabled?: boolean,
  required?: boolean,
  readOnly?: boolean,
  multiple?: boolean,
  id?: string,
  className?: string,
  descriptionId?: string,
  labelId?: string,
  errorId?: string,
};

const Input = (props: Props) => {
  const {
    name,
    className,
    color,
    placeholder,
    disabled,
    required,
    readOnly,
    type,
    value,
    descriptionId,
    labelId,
    errorId,
    invalid,
    onChange,
  } = props;

  const Tag = type === 'textarea' ? type : 'input';
  const classes = classNames(
    {
      input: true,
      'input--multiple': type === 'textarea',
      [`input--${color || 'primary'}`]: true,
    },
    className,
  );

  const attributes = {
    placeholder,
    name,
    onChange,
    value,
    disabled: disabled ? 'disabled' : undefined,
    required: required ? 'required' : undefined,
    readOnly: readOnly ? 'readonly' : undefined,
    type: type !== 'textarea' ? type : undefined,
    'aria-describedby': descriptionId,
    'aria-labelledby': labelId,
    'aria-errormessage': errorId,
    'aria-invalid': invalid ? 'true' : undefined,
  };

  return <Tag className={classes} {...attributes} />;
};

export default Input;
